export default {
  "site": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب جديد"])},
    "pagesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمتلك تطبيق سيفي برودكاست الكثير من المميزات ونقوم بعرض بعض مميزات التطبيق"])},
    "appPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحات التطبيق"])},
    "Awamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم وبرمجه مؤسسه أوامر الشبكه"])},
    "cvCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة لموقع سيڤي"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحميلات"])},
    "companiesNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركات المسجلة"])},
    "userNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المستفيدين"])},
    "clientNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد العملاء"])},
    "statDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نستعرض لكم هنا الاحصائيات الخاصه بالتطبيق"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات التطبيق"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])}
  },
  "dash": {
    "addJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة اعلان وظيفي"])}
  },
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث"])},
    "addAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه اعلان وظيفي"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات التوظيف"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات الوظائف المعلنة"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الاساسية"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات الموظفين"])},
    "subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراكات"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])}
  },
  "header": {
    "dash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوظيف اصبح أسهل"])}
  },
  "home": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نستعرض لكم بعض احصائيات التوظيف"])},
    "sta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحصائيات"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي"])},
    "employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوظبف"])},
    "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتقدمين الذين تم توظيفهم"])},
    "appliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتقدمين على المنشأة"])},
    "rateDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط نسبة التطابق للمتقدمين الذين تم توظيفهم"])},
    "avr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة"])},
    "applyOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتقدمين على الاعلان"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلانات"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركة"])},
    "job_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات التوظيف"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراكات"])}
  },
  "search": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث"])},
    "searchEmp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن الموظفين من هنا"])},
    "jobName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسمى الوظيفي"])},
    "spa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص العلمي"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤهل التعليمي"])},
    "jobType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوظيفة"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات"])},
    "exper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخبرة"])},
    "cer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادات المهنية"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجال الوظيفي"])},
    "do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "most": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاكثر تطابقا"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاطلاع علي بيانات الموظفين الاكثر تطابقا"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض البروفايل"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "map_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الإقامة"])},
    "profileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الموظف"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])}
  },
  "job": {
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك اضافة اعلان وظيفي من هنا"])},
    "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخال المسمى الوظيفي"])},
    "chooseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع الوظيفة"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التخصص"])},
    "chooseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اسم التخصص"])},
    "cat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة الوظيفة"])},
    "chooseCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال الوظيفة"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "chooseGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع الجنس"])},
    "chooseQual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال المؤهل المطلوب"])},
    "chooseExper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار الخبرة المطلوبة"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام والمسئوليات"])},
    "resPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل المهام والمسئوليات"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الراتب"])},
    "ifExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ان وجد"])},
    "chooseSkill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار المهارات المطلوبة"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة التطابق"])},
    "choosePer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار نسبة التطابق"])},
    "chooseCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار المدينة"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الإقامة"])},
    "cer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادات المهنية"])},
    "cerDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار الشهادات المهنية"])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الوظيفة"])},
    "conPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل شروط الوظيفة"])},
    "chooseEmp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المجال الوظيفي"])}
  },
  "order": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات التوظيف"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم عرض هنا جميع الوظائف المعلنه وجميع طلبات التوظيف التي تم التقديم عليها"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر العرض"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوام كامل"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوام جزئي"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل عن بعد"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])}
  },
  "set": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الاساسية"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك التعديل علي جميع تعديلات البيانات الاساسيه الخاصه بك"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك رقع صورة الغلاف"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنشأة"])},
    "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المنشأة"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "userNamePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل اسم المستخدم"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المنشأة"])},
    "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشأة حكومي"])},
    "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشأة خاصة"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط المنشأة"])},
    "activePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل نشاط المنشأة"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشخص المسئول"])},
    "resPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الشخص المسئول"])},
    "com": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم السجل التجاري"])},
    "comPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم السجل التجاري"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل البريد الالكتروني"])},
    "resPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشخص المسئول"])},
    "resPersonPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل  الشخص المسئول"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "phonePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الجوال"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة"])},
    "regionPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار المنطقة"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "CityPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار المدينة"])},
    "comp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الشركة"])},
    "compPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل وصف الشركة هنا"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التعديلات"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الحساب"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])},
    "haveAnAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك حساب بالفعل "])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])}
  },
  "emp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموظفين"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة موظف جديد"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل البيانات"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الموظف"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد من حذف هذا الموظف"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تراجع"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الموظف بنجاح"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات الموظفين"])},
    "addEmp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة موظف"])},
    "empName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الموظف"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الموظف"])},
    "trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التحويلة"])},
    "transPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم التحويلة"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد الكل"])}
  },
  "sub": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراكات"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الباقة"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الباقة"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الباقة"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء الباقة"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الاشتراك"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالية"])},
    "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجديد"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر طريقة الدفع"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادفع الان"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "emailCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التواصل عبر البريد الإلكتروني"])},
    "whats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التواصل عبر الواتساب"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التواصل عبر الهاتف"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك ارسال رساله من هنا"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الاسم هنا"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتك"])},
    "messagePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رسالتك هنا"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البروفايل الشخصي"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الشخصية"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
    "nat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])}
  },
  "details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الوظيفة"])},
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متطلبات الوظيفة"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤهل"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوظيفة"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "det": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقدمين للوظيفة"])},
    "delAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الاعلان"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
    "delDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد من رغبتك بحذف اعلان الوظيفه الخاصه بك"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الوظيفة"])}
  },
  "auth": {
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "phoneDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الجوال هنا"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "passwordPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور هنا"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "haveAnAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب "])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب جديد"])},
    "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التحقق"])},
    "otpPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال كود التحقق الذي تم ارساله الى رقم جوالك ليتم تغيير رقم الجوال"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
    "haveNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تستلم الكود ؟"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعد ارسال "])},
    "remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت المتبقي"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانية"])},
    "conPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
    "conPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل تأكيد كلمة المرور"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ البيانات"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك"])},
    "loginDis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال البيانات التالية لتسجيل الدخول"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع للرئيسية"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "cityPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد المدينة"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    "countryPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الدولة"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة العلمية"])},
    "qualPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الدرجة العلمية"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص العام"])},
    "fieldPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد التخصص العام"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص الدقيق"])},
    "specialPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد التخصص الدقيق"])}
  },
  "main": {
    "curComp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركة الحالية"])},
    "infodef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الموهل"])},
    "goChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الذهاب للمحاثة"])},
    "employ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توظيف"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف المعلنة"])},
    "cvs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات المرفوعة"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيرة الذاتية"])},
    "addsall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الراتب"])},
    "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ بداية الاعلان"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ نهاية الاعلان"])},
    "startdatePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال تاريخ بداية الاعلان"])},
    "enddatePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال تاريخ نهاية الاعلان"])},
    "ved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفيديو التعريفي"])},
    "noapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد متقدمين"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد موظفين الى الان"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطابق بنسبة "])},
    "cert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادات المهنية"])},
    "sendcomp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك ارسال شكوى او اقتراح من هنا"])},
    "infodefemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجال العمل"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انثى"])},
    "applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقدم"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشترك الآن"])},
    "most": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأكثر رواجاً"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ر.س"])},
    "startWithPack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبدأ هنا مع هذه الباقات (إختر باقة)"])}
  }
}