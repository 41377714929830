<template>
    <!-- side bar  -->
    <sideBarVue />
    <!-- header  -->
    <dashHeaderVue />

    <section class="content px-3 pt-3" v-if="!loaderPack">
        <!-- dashboard  -->
        <homeViewVue :subscribtion="subscribtion" />
        <section id="subs" v-if="!subscribtion">

            <h5 class="mt-4 mb-4 text-center">{{ $t('main.startWithPack') }}</h5>
            <div class="row">
                <!-- single sub card -->
                <div class="col-md-4 mb-3" v-for="pkg in packages" :key="pkg.id">
                    <section class="position-relative single_sub pt-5 pb-2 px-3">
                        <!-- sub image  -->
                        <!-- <div class="sub_image mb-3">
                            <img :src="pkg.image" alt="subscribtion icon">
                        </div> -->
                        <!-- <div class="ribbon-container"></div> -->
                        <div class="ribbon" v-if="pkg.is_best_seller">{{ $t('main.most') }}</div>
                        <!-- title  -->
                        <h6 class="fw-bold pkg_title mb-3">
                            {{ pkg.title }}
                        </h6>
                        <span class="fw-6 fs-16 mb-3 pkg_price">
                            {{ pkg.price }} {{ $t('main.currency') }}
                        </span>
                        <!-- desc  -->
                        <!-- <p class="text-center grayColor">
                            {{ pkg.description }}
                        </p> -->
                        <div class="pkg_description" v-html="pkg.description"></div>
                        <!-- input  -->
                        <input type="radio" name="pkg" class="checkPkg" @change="setPkg(pkg.id, pkg.free)"
                            :value="pkg.id">
                        <label for="" class="package_label">
                        </label>
                    </section>
                </div>
            </div>

            <div class="flex_center mb-5">
                <button class="main_btn w-40 pt-2 pb-2 mb-3 addSubscription" @click.prevent="chooseSubscription" :disabled="package_id==null || disabled">
                    <span v-if="!disabled"> {{ $t('main.subscribe') }} </span>
                    <div class="spinner-border" role="status" v-if="disabled">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
            </div>

        </section>
    </section>
    <!-- select payment way  -->
    <Dialog  v-model:visible="pay_type" modal :style="{ width: '40vw' }">
        <h5 class="fw-bold mainColor text-center mb-4"> 
            اختر وسيلة الدفع
        </h5> 

        <section class="pay_methods">
            <div class="single_pay position-relative flex_between">
                <input type="radio" name="payment_type" value="mada" class="payment_input" v-model="payment_type" id="">
                <h5>MADA</h5>
                <div class="mx-3">
                    <img :src="require('@/assets/imgs/mada.png')" alt="">
                </div>
            </div>
            <div class="single_pay position-relative flex_between">
                <input type="radio" name="payment_type" value="visa" class="payment_input" v-model="payment_type" id="">
                <h5>VISA</h5>
                <div class="mx-3">
                    <img :src="require('@/assets/imgs/visa.png')" alt="">
                </div>
            </div>
            <div class="single_pay position-relative flex_between">
                <input type="radio" name="payment_type" value="master" class="payment_input" v-model="payment_type" id="">
                <h5>MASTER CARD</h5>
                <div class="mx-3">
                    <img :src="require('@/assets/imgs/master.png')" alt="">
                </div>
            </div>
            <!-- <div class="single_pay position-relative flex_between">
                <input type="radio" name="payment_type" value="AMEX"  class="payment_input" v-model="payment_type" id="">
                <h5>AMEX</h5>
                <div class="mx-3">
                    <img :src="require('@/assets/imgs/amex.svg')" alt="">
                </div>
            </div> -->

            <div class="flex_center">
                <button class="main_btn btn pt-2 pb-2 px-5" :disabled="disabled" @click.prevent="updateSub"> ادفع الان </button>
            </div>
        </section>
    </Dialog>
    <!-- pay  -->
    <Dialog v-model:visible="pay" modal :style="{ width: '65vw' }">
        <h5 class="fw-bold mainColor text-center mb-2"> 
            {{  $t('sub.payNow')  }}
        </h5>

        <iframe :src="payment_gate_link" frameborder="0" class="payment_gate"></iframe>
    </Dialog>

    <Toast />
</template>

<script>
import sideBarVue from '@/components/layout/sideBar.vue';
import dashHeaderVue from '@/components/layout/dash_header.vue';
import homeViewVue from '@/components/dashboard/subscribeComonent.vue';
import axios from 'axios';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
// import Toast from 'primevue/toast';


export default {

    data() {
        return {
            loaderPack: true,
            subscribtion: false,
            dataFetched: true,
            package_id: null,
            disabled: false,
            free: null,
            pay_type: false,
            payment_gate_link: '',
            pay: false,
            payment_type : ''
        }
    },
    computed: {
        packages() {
            return this.$store.state.packages;
        }
    },
    components: {
        sideBarVue,
        dashHeaderVue,
        homeViewVue,
        Dialog,
        Toast
    },
    created() {
        // if( this.subscribtion == true ){
        //     setTimeout(() => {
        //     // Assuming `fetchPackages` is a method to fetch your data
        //     this.dataFetched = false; // Mark data as fetched
        //     }, 1000); // Adjust the delay as needed
        // }
        this.$store.dispatch('getPackages');
        // console.log(this.$store.state);
        
    },
    methods: {
  
        setPkg(id, type) {
            // console.log(id , type)
            this.package_id = id;
            this.free = type
        },
        chooseSubscription() {
            this.pay_type = true
        },
        async addSubscription() {
            if (this.free == true || this.free == false) {
                this.disabled = true;
                const fd = new FormData();
                fd.append('package_id', this.package_id);

                const token = localStorage.getItem('token');
                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                await axios.post('company/subscription/add', fd, { headers })
                    .then((res) => {
                        if (res.data.key === 'success') {
                            this.$toast.add({ severity: 'success', summary: res.data.msg, life: 3000 });
                            this.disabled = false;
                            setTimeout(() => {
                                this.$router.push('/dashboard');
                            }, 1000);
                        } else {
                            this.$toast.add({ severity: 'error', summary: res.data.msg, life: 3000 });
                            this.disabled = false;
                        }
                    })
                    .catch((err) => {
                        this.$toast.add({ severity: 'error', summary: err, life: 3000 });
                        this.disabled = false;
                    })
            } else {
                this.pay_type = true;
            }

        },

        // setIsSub(isSub) {
        //     console.log(`=== ${isSub}`);
        //     this.subscribtion = isSub
            
        // },


        async updateSub() {
            this.disabled = true;
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const fd = new FormData();
            fd.append('card_type', this.payment_type);
            fd.append('package_id', this.package_id);
            await axios.post('company/package/payment', fd, { headers })
                .then((res) => {
                    if (res.data.key === 'success') {
                        // this.$toast.add({ severity: 'success', summary: res.data.msg, life: 3000 });
                        this.disabled = false;
                        this.pay_type = false;
                        setTimeout(() => {
                            this.pay = true;
                        }, 1000);
                        this.payment_gate_link = res.data.data.checkoutUrl;
                    } else {
                        this.$toast.add({ severity: 'error', summary: res.data.msg, life: 3000 });
                        this.disabled = false;
                    }
                })

        },


        getPackages(){
            this.loaderPack = true
            const token = localStorage.getItem('token');

      // Create headers object with Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };
       axios.get('packages' , {headers})
      .then( (res)=>{
        this.subscribtion = res.data.is_subscribed
        this.loaderPack = false
      })
    },

    },
    mounted() {
        // this.setIsSub()
        this.getPackages()
    }
}
</script>

<style scoped>
.pkg_title{
    padding: 20px 100px;
    background-color: #293155;
    color: white;
    /* z-index: 1; */
}
.pkg_price{
    font-size: 18px;
    font-weight: bold;
    color: #293155;
    /* z-index: 1; */
}
.pkg_description{
    font-size: 15px;
    font-weight: 500;
    /* z-index: 1; */
}
.addSubscription{
    height: 45px;
    font-size: 16px;
    width: 500px;
}
/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  z-index: 1;
  /* position: relative; */
}
.ribbon {
  --f: .5em; /* control the folded part */
  
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51%/var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px));
  transform: translate(calc((cos(45deg) - 1)*100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background-color: #ff1616; /* the main color  */
}
</style>